<template>
  <div>
    <!-- main page -->
    <div class="vx-row mb-12">
      <vs-button v-on:click="SubmitApproval()" color="primary"
        >Submit</vs-button
      >
    </div>
    <div class="vx-row mb-12">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{
                    this.dataEnd == 0
                      ? 0
                      : this.limitShow * (this.isActive - 1) + 1
                  }}
                  - {{ this.limitShow * (this.isActive - 1) + dataEnd }} of
                  {{ this.recordsTotal }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in limits"
                  v-bind:key="item"
                  @click="changeLimit(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div class="mx-auto"></div>
          <div class="vx-col sm:w-1/3 w-full">
            <div class="flex flex-wrap items-center justify-between">
              <vs-input
                class="mb-4 md:mb-0 mr-4 w-full"
                v-model="search"
                placeholder="Search by : SO Code or Customer Name"
              />
            </div>
          </div>
        </div>

        <vs-table :data="dataSalesOrder" stripe border>
          <template slot="thead">
            <vs-th>
              <!-- <div class="vx-row mb-12">
                <div class="vx-col">
                  <vs-checkbox
                    v-on:click="checkAllApproval('approve')"
                    v-model="approveAll"
                    >Approve</vs-checkbox
                  >
                </div>
                <div class="vx-col">
                  <vs-checkbox
                    class="vs-checkbox-danger"
                    v-on:click="checkAllApproval('reject')"
                    v-model="rejectAll"
                    >Reject</vs-checkbox
                  >
                </div>
                <div class="vx-col">
                  <vs-checkbox
                    class="vs-checkbox-warning"
                    v-on:click="checkAllApproval('inquiry')"
                    v-model="inquiryAll"
                    >Inquiry</vs-checkbox
                  >
                </div>
              </div> -->
              <div class="vx-row mb-12">Action</div>
            </vs-th>
            <vs-th>
              <div class="vx-row mb-12">Sales Order</div>
            </vs-th>
            <vs-th>
              <div class="vx-row mb-12">Customer</div>
            </vs-th>
            <vs-th>
              <div class="vx-row mb-12">Company</div>
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indexSO" v-for="(dt, indexSO) in data">
              <vs-td>
                <div class="vx-row mb-4">
                  <div class="vx-col">
                    <vs-checkbox
                      :checked="approved.includes(dt.Code)"
                      v-on:click="
                        checkApproval(dt.Code, 'approve', dt, indexSO)
                      "
                      >Approve</vs-checkbox
                    >
                  </div>
                  <div class="vx-col">
                    <vs-checkbox
                      class="vs-checkbox-danger"
                      :checked="rejected.includes(dt.Code)"
                      v-on:click="checkApproval(dt.Code, 'reject', dt, indexSO)"
                      >Reject</vs-checkbox
                    >
                  </div>
                  <div class="vx-col">
                    <vs-checkbox
                      class="vs-checkbox-warning"
                      :checked="inquiry.includes(dt.Code)"
                      v-on:click="checkApproval(dt.Code, 'inquiry', dt, indexSO)"
                      >Inquiry</vs-checkbox
                    >
                  </div>
                </div>
                <div class="vx-col">
                  <vs-select label="Select Reason" v-model="dt.ApprovalRemark">
                    <vs-select-item
                      :key="index"
                      :value="item.reason"
                      :text="item.reason"
                      v-for="(item, index) in reasonOptions"
                    />
                  </vs-select>
                  <!-- <multiselect
                    v-show="dt.ShowReasonSelect"
                    v-model="dt.ApprovalRemark"
                    :options="reasonOptions"
                    placeholder="Type to search"
                    label="reason"
                    track-by="ID"
                    :showNoOptions="false"
                    :max-height="125"
                    @select="updateRemark(dt.Code, $event, dt, indexSO)"
                  >
                    <span slot="noResult">Oops! No data found</span>
                  </multiselect> -->
                </div>
              </vs-td>
              <vs-td>
                <vs-button
                  size="[detail ? 'small' : '', '']"
                  v-on:click="clickSOCode(dt)"
                  color="primary"
                  type="gradient"
                >
                  {{ dt.Code }}
                </vs-button>
                <br /><br />
                SO Date : {{ dateFormat(dt.Date) }} <br />
                PO Ref. Code :
                {{ dt.PoReferenceCode ? dt.PoReferenceCode : "-" }} <br />
                Source : {{ dt.Source }} <br />
                Sales : <b>{{ dt.Salesman.Name }}</b> <br />
                Channel : ({{ dt.SalesChannel.Code }})
                {{ dt.SalesChannel.Name }} <br />
                Delivery Type :
                <span v-if="dt.DeliveryTypeID != 0 && dt.DeliveryType">
                  ({{ dt.DeliveryType.Code }}) {{ dt.DeliveryType.Name }} </span
                ><span v-if="dt.DeliveryTypeID == 0">-</span><br />
                Delivery Date : {{ dateFormat(dt.DueDate) }} <br />
                Total : <b>{{ "Rp. "+formatCurrency(dt.Total) }}</b>
                <span
                  v-if="dt.Total > dt.CustomerLimit.Balance"
                  style="color: red"
                  >(exceeds customer limit :
                  {{
                    customerLimitPercentage(dt.Total, dt.CustomerLimit.Balance)
                  }}% )</span
                >
                <br />
                Total Line : {{ dt.SalesOrderLine.length }} <br />
                Notes : {{ dt.Notes }}<br />
                Outstanding :
                <span style="color: red"
                  ><b>{{ "Rp. "+formatCurrency(dt.Customer.Outstanding) }}</b></span
                >
              </vs-td>
              <vs-td>
                Channel : {{ dt.Customer.CustomerCategory.Name }} <br />
                Code : {{ dt.Customer.Code }} <br />
                Name : {{ dt.CustomerName }} <br />
                Bill to : {{ dt.BillAddress.Code }} - {{ dt.BillAddress.ContactName }}<br />
                Ship to : {{ dt.ShipAddress.Code }} - {{ dt.ShipAddress.ContactName }}<br />
                Payment term : {{ dt.PaymentTerm.Name }} <br />
                Limit : <b>{{ "Rp. "+ formatCurrency(dt.CustomerLimit.Balance) }}</b>
              </vs-td>
              <vs-td>
                Code : {{ dt.Company.Code }} <br />
                Name : {{ dt.Company.Name }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <vs-pagination
          style="padding-top: 5px"
          :total="maxPage"
          v-model="currentPage"
          :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'"
          :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"
        />
      </div>
    </div>
    <vs-popup fullscreen title="Sales Order Lines" :active.sync="detail">
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <detailSelectedSo :selectedSO="selectedSO"></detailSelectedSo>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import detailSelectedSo from "./detail_selected_so.vue";
import moment from "moment";

export default {
  components: {
    detailSelectedSo,
  },
  mounted() {
    this.selectedSO = this.dataSalesOrder;
    this.getReasonOptions();
  },
  props: ["territory", "startDate", "endDate"],
  data() {
    return {
      reasonOptions: [],
      selectedSO: {},
      dataSalesOrder: [
        {
          SalesOrderLine: [{}],
          Customer: {
            Code: "",
            Name: "",
            Address: "",
            Phone: "",
            CustomerCategory: {
              Name: "",
            },
          },
          CustomerLimit: {
            Balance: 0,
          },
          Company: {
            Code: "",
            Name: "",
          },
          SalesChannel: {
            Code: "",
            Name: "",
          },
          BillAddress: {
            Address: "",
            City: "",
            Country: "",
          },
          ShipAddress: {
            Address: "",
            City: "",
            Country: "",
          },
          Salesman: {
            Name: "",
          },
          PaymentTerm: {
            Name: "",
          },
        },
      ],
      salesOrderCode: "",

      approved: [],
      approveAll: false,
      rejected: [],
      rejectAll: false,
      inquiry: [],
      inquiryAll: false,
      remark: [],
      remarkCode: [],

      limits: [10, 25, 50, 100, "All"],
      limitShow: 10,
      drawData: 0,
      isActive: 1,
      modelCheck: [],
      search: "",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      recordsTotal: 0,
      maxPage: 2,
      dataEnd: 0,
    };
  },
  watch: {
    territory: function () {
      this.getData();
    },
    search: function () {
      this.getData();
    },
    startDate: function () {
      this.getData();
    },
    endDate: function () {
      this.getData();
    },
  },
  computed: {
    currentPage: {
      get() {
        return 1;
      },
      set(page) {
        this.page(page);
      },
    },
  },
  methods: {
    async getReasonOptions() {
      let params = {
        reason_group: "Reject SO",
      };
      let resp = await this.$http.get("/api/v1/reasons", { params });

      if (resp.code == 200) {
        this.reasonOptions = resp.data.records;
      } else {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: resp.message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    customerLimitPercentage(order, balance) {
      let result = ((order - balance) / balance) * 100;
      return result.toFixed(2);
    },

    dateFormat(date) {
      if (date) {
        return moment(String(date)).utcOffset(0).format("DD/MM/YYYY hh:mm");
      }
    },

    formatCurrency(nominal) {
      if (nominal) {
        return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        return 0;
      }
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    getSOLine() {
      const search = this.salesOrderCode;
      if (search) {
        this.selectedSO = this.dataSalesOrder.filter(
          (so) => so.Code.indexOf(search) > -1
        )[0]; // filter + get 1st record
      }
    },

    clickSOCode(dt) {
      this.closeDetail();
      setTimeout(function () {}, 3000);
      this.salesOrderCode = dt.Code;
      this.getSOLine();
      this.detail = true;
      this.scrollToTop();
    },

    closeDetail() {
      this.detail = false;
    },

    checkApproval(soCode, action, dt, indexSO) {
      if (action == "approve") {
        if (this.approved.includes(soCode)) {
          this.approved.splice(this.approved.indexOf(soCode), 1); // unchecked approve
        } else {
          this.dataSalesOrder[indexSO].ShowReasonSelect = false;
          this.approved.push(soCode); // checked approve
          this.rejected = this.rejected.filter((value) => value !== soCode); // unchecked reject
          this.inquiry = this.inquiry.filter((value) => value !== soCode); // unchecked inquiry
        }
      } else if (action == "reject") {
        if (this.rejected.includes(soCode)) {
          this.dataSalesOrder[indexSO].ShowReasonSelect = false;
          this.rejected.splice(this.rejected.indexOf(soCode), 1); // unchecked reject
        } else {
          this.dataSalesOrder[indexSO].ShowReasonSelect = true;
          this.rejected.push(soCode); // checked reject
          this.approved = this.approved.filter((value) => value !== soCode); // unchecked approve
          this.inquiry = this.inquiry.filter((value) => value !== soCode); // unchecked inquiry
        }
      } else if (action == "inquiry") {
        if (this.inquiry.includes(soCode)) {
          this.dataSalesOrder[indexSO].ShowReasonSelect = false;
          this.inquiry.splice(this.inquiry.indexOf(soCode), 1); // unchecked inquiry
        } else {
          this.dataSalesOrder[indexSO].ShowReasonSelect = true;
          this.inquiry.push(soCode); // checked inquiry
          this.approved = this.approved.filter((value) => value !== soCode); // unchecked approve
          this.rejected = this.rejected.filter((value) => value !== soCode); // unchecked reject
        }
      }
    },

    checkAllApproval(action) {
      if (action == "approve") {
        this.rejectAll = false;
        if (this.approveAll == false) {
          for (var i = 0; i < this.dataSalesOrder.length; i++) {
            if (this.approved.includes(this.dataSalesOrder[i].Code)) {
              //
            } else {
              this.approved.push(this.dataSalesOrder[i].Code);
              this.rejected.splice(this.approved.indexOf(i), 1); // unchecked reject
            }
          }
        } else {
          for (var j = 0; j < this.dataSalesOrder.length; j++) {
            this.approved.splice(
              this.approved.indexOf(this.dataSalesOrder[j].id),
              1
            );
          }
        }
      } else if (action == "reject") {
        this.approveAll = false;
        if (this.rejectAll == false) {
          for (var k = 0; k < this.dataSalesOrder.length; k++) {
            if (this.rejected.includes(this.dataSalesOrder[k].Code)) {
              //
            } else {
              this.rejected.push(this.dataSalesOrder[k].Code);
              this.approved.splice(this.rejected.indexOf(k), 1); // unchecked approve
            }
          }
        } else {
          for (var l = 0; l < this.dataSalesOrder.length; l++) {
            this.rejected.splice(
              this.rejected.indexOf(this.dataSalesOrder[l].id),
              1
            );
          }
        }
      }
    },

    updateRemark(SOCode, $event, dt, indexSO) {
      // change dt.ApprovalRemark to $event.reason
      this.dataSalesOrder[indexSO].ApprovalRemark = $event.reason;

      if (this.remarkCode.includes(SOCode)) {
        const idx = this.remarkCode.indexOf(SOCode);
        this.remark.splice(idx, 0, event.reason);
      } else {
        this.remarkCode.push(SOCode);
        this.remark.push(event.reason);
      }
    },

    page(page) {
      this.getData(page);
      return page;
    },

    changeLimit(limit) {
      this.limitShow = limit;
      this.getData(this.isActive);
    },

    getData(page = 1) {
      if (typeof this.territory == "undefined") {
        return true;
      }

      this.isActive = page;
      this.$vs.loading();

      this.$http
        .get("/api/v1/sales-order", {
          params: {
            territory_code: this.territory,
            start_created_at: this.startDate,
            end_created_at: this.endDate,
            approval_status: 0,
            approval_manager: 3,
            validation_status: 2,
            allocation_status: 0,
            search: this.search,
            limit: this.limitShow,
            offset: this.limitShow * (page - 1),
            order: "asc",
            is_use_approval_matrix: true,
          },
        })
        .then((resp) => {
          // console.log(resp.data);
          if (resp.code == 200) {
            this.drawData = this.drawData + 1;
            this.dataSalesOrder = resp.data.SalesOrders;
            // add default false showReasonSelect
            this.dataSalesOrder.forEach((element) => {
              element.ApprovalRemark = "";
              element.ShowReasonSelect = false;
            });
            this.recordsTotal = resp.data.RecordsTotal;
            this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
            this.dataEnd = resp.data.ResultsTotal;
            this.checkedAll = false;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        });
    },

    SubmitApproval() {
      this.$vs.loading();
      // const formData = new FormData();
      // formData.set('approved_sales_order_codes', this.approved);
      // formData.set('rejected_sales_order_codes', this.rejected);
      // var json_form = JSON.stringify(Object.fromEntries(formData));
      // console.log(json_form);

      // let temp_remark = [];
      // for (let index = 0; index < this.remarkCode.length; index++) {
      //     const code = this.remarkCode[index];
      //     temp_remark[code] = this.remark[index];
      // }
      this.$http
        .post("/api/v1/sales-order/approval-by-code", {
          approved_sales_order_codes: this.approved,
          rejected_sales_order_codes: this.rejected,
          inquiry_sales_order_codes: this.inquiry,
          type: "release",
          remarks: this.remark,
          remark_codes: this.remarkCode,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });

            // reset selected
            this.approved = []
            this.approveAll = false
            this.rejected = []
            this.rejectAll = false
            this.inquiry = []
            this.inquiryAll = false
            this.remark = []
            this.remarkCode = []

            this.getData();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
